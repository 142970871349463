/*
 * Copyright 2023 Thoughtworks, Inc.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
@import "../../global/common";

.main-container {
  display: flex;
}

.navigation {
  display: flex;
  min-width: 200px;
  border-radius: $global-border-radius;
  background: $white;
  padding: 8px 16px;
  margin-right: 16px;
  width: 200px;
}

.entity-config-container {
  display: flex;
  border-radius: $global-border-radius;
  background: $white;
  flex-grow: 1;
  flex-direction: column;
}

.steps {
  font-weight: 600;
  padding: 8px 16px;
  word-break: break-all;
  word-wrap: break-word;
  font-size: 16px;

  a {
    text-decoration: none;
    cursor: pointer;

    &:hover {
      text-decoration: none;
    }
  }
}

.material-tab {
  table {
    td {
      vertical-align: middle;
    }
  }
}

.material-tab.editable {
  td:last-child {
    width: 100px;
  }
}

.radio-label-with-inline-textfield {
  display: flex;
  flex-direction: row;

  span {
    display: flex;
    margin-right: 5px;
  }

  input {
    margin-left: 5px;
    width: 50px;
  }
}

.help {
  color: $go-disabled;
  display: block;
  margin-bottom: 5px;
  font-style: italic;
}

.button-container {
  padding: 15px 0 15px 5px;
}

.missing-plugin {
  color: $warning-txt;
}

.name-link {
  text-decoration: none;
}

.icon-check {
  @include icon-before($type: $fa-var-check, $color: $go-green);

  &::before {
    margin-left: -10px;
  }
}

.icon-error {
  @include icon-before($type: $fa-var-exclamation-circle, $color: $go-danger);

  &::before {
    margin-left: -10px;
  }
}
