/*
 * Copyright 2023 Thoughtworks, Inc.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
@import "../../global/common";

.configuration-details-container {
  display: block;
}

.configuration-details-header {
  @include icon-before($type: $fa-var-angle-right, $size: 16px);

  position: relative;
  padding-left: 17px;
  cursor: pointer;
  margin: 0 0 20px 0;

  &::before {
    position: absolute;
    left: 1px;
    top: 1px;
    margin: 0;
    transition: $transition;
  }

  &.expanded {
    &::before {
      transform: rotate(90deg);
      transition: $transition;
      left: 3px;
      top: 2px;
    }
  }
}

.configuration-details {
  display: none;
  margin-left: 20px;

  &.expanded {
    display: block;
  }
}

.package-repository-crud-actions {
  margin-left: 20px;
}

.tips {
  background: $white;
  padding: 20px;
}

.warning {
  margin-right: 4px;

  i {
    color: $failed;
  }
}
